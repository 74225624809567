@import 'style/colors.scss';

$defaultPadding: 15px;
$largePadding: 24px;
$largerFontSize: 18px;
$fontSizeButton: 15px;
$smallPadding: 7px;
$fontSizeHeader3: calc(16px * 0.8);


$textColor: $hqvblack;
$textDisabledColor: #b0b0b1;
$accentDisabledColor: #e37351;
$accentColor: #f35321;
$accentFocusColor: #d05321;
$borderColor: #999aa2;
$borderDisabledColor: #bbb;

$defaultAnimationTime: .2s;
$defaultBackgroundColorAnimation: background-color $defaultAnimationTime;
$defaultColorAnimation: color $defaultAnimationTime;

.App {
  margin: 1em;

  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    color: $hqvblue;
  }

  button.action {
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color;
    border: none;
    &:focus {
      outline-width: 0;
    }
    &[disabled] {
      cursor: not-allowed;
    }

    &.bold {
      font-size: $largerFontSize;
      padding: $largePadding calc($largePadding * 2);
      &::after {
        font-size: $largerFontSize;
        padding: 0;
        margin-left: $largePadding;
      }
    }
    &.right {
      &::after {
        content: "\00276f";
      }
    }
    &.accented {
      color: #f5f5f5;
      background-color: $accentColor;
      &[disabled] {
        background-color: $accentDisabledColor;
      }

      &:hover:not([disabled]) {
        background-color: $accentFocusColor;
        color: #fff;
      }
      &:focus {
        background-color: $accentFocusColor;
      }
    }
  }
}
