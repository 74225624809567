$hqvgrey1: #f3f2f0;
$hqvgrey2: #e2e3e6;
$hqvgrey3: #d2d2d2;
$hqvgrey4: #a0a0a0;
$hqvgrey5: #595959;
$hqvsteelgrey: #3d3c3c;
$hqvcpgrey: #2c2c2c;
$hqvblack: #201e1e;
$hqvorange: #f35321;
$hqvblue: #273a60;
