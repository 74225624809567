@import 'style/colors.scss';

@font-face {
  font-family: 'Trade Gothic';
  src: url('./style/fonts/HusqvarnaGothic-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Trade Gothic';
  src: url('./style/fonts/HusqvarnaGothic-Bold.woff') format('woff');
  font-weight: bold;
}

body {
  font-family: 'Trade Gothic', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: $hqvblack;
  background-color: $hqvgrey1;
  box-sizing: border-box;
}
